import Vue from 'vue'
import notificationApi from '@/api/notification.api'


const state = {
  itemsByVehicle: {},
  initialTableItems: {},
  tableItems: {},
  totalCount: {}
}


const getters = {
  notificationsByVehicle: (state) => (vehicleId) => {
    return state.itemsByVehicle[vehicleId] || []
  },

  notificationsTableItems: (state) => (dealerId) => {
    return state.tableItems[dealerId] || []
  },

  notificationsTotalCount: (state) => (dealerId) => {
    return state.totalCount[dealerId] || 0
  }
}


const actions = {
  async loadNotificationsByVehicle ({ commit }, vehicleId) {
    const response = await notificationApi.getNotificationsByVehicle(vehicleId)
    const items = response.data
    commit('SET_ITEMS_BY_VEHICLE', { vehicleId, items })
    return items
  },

  async loadNotificationsTableItems ({ commit }, { dealerId, chainId, search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize

    const response = dealerId === ''
      ? await notificationApi.getNotifications(skip, pageSize, search, chainId)
      : await notificationApi.getNotificationsByDealer(dealerId, skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', { dealerId, items })
    commit('SET_TOTAL_COUNT', { dealerId, totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', { dealerId, items })
    }

    return items
  },

  restoreInitialNotificationsTableItems ({ commit, state }, dealerId) {
    if (dealerId !== null && state.initialTableItems[dealerId]) {
      commit('SET_TABLE_ITEMS', { dealerId, items: state.initialTableItems[dealerId] })
    }
  }
}


const mutations = {
  SET_ITEMS_BY_VEHICLE (state, { vehicleId, items }) {
    Vue.set(state.itemsByVehicle, vehicleId, items)
  },

  SET_TABLE_ITEMS (state, { dealerId, items }) {
    Vue.set(state.tableItems, dealerId, items)
  },

  SET_INITIAL_TABLE_ITEMS (state, { dealerId, items }) {
    Vue.set(state.initialTableItems, dealerId, items)
  },

  SET_TOTAL_COUNT (state, { dealerId, totalCount }) {
    Vue.set(state.totalCount, dealerId, Math.max(0, totalCount))
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
