import apiClient from './apiClient'


const getTemplateTypes = () => {
  return apiClient.get('/TemplateType')
}


export default {
  getTemplateTypes
}
