import Vue from 'vue'
import VueRouter from 'vue-router'
import { userRole } from '@/constants'
import { authGuard } from './authGuard'


Vue.use(VueRouter)


const routes = [
  // {
  //   path: '/*',
  //   redirect: { name: 'underMaintenance' }
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('@/views/Disclaimer'),
    beforeEnter: authGuard
  },
  {
    path: '/cars',
    alias: '/vehicles',
    name: 'vehicles',
    component: () => import('@/views/Vehicles'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.user, userRole.superAdmin, userRole.admin, userRole.chainAdmin]
    }
  },
  // {
  //   path: '/booking',
  //   name: 'booking',
  //   component: () => import('@/views/Home'),
  //   beforeEnter: authGuard,
  //   meta: {
  //     layout: 'auth-layout'
  //   }
  // },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/Services'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.salesPerson]
    }
  },
  {
    path: '/chains',
    name: 'chains',
    component: () => import('@/views/Chains'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.chainAdmin]
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/Reports'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
    }
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: () => import('@/views/Dealers'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.superAdmin, userRole.chainAdmin, userRole.chainAdmin]
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/Notifications'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout',
      roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
    },
    children: [
      {
        path: 'dealer',
        name: 'dealerSettings',
        component: () => import('@/views/Settings/Dealer'),
        beforeEnter: authGuard,
        meta: {
          layout: 'auth-layout',
          roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
        }
      },
      {
        path: 'service-types',
        name: 'serviceTypes',
        component: () => import('@/views/Settings/ServiceTypes'),
        beforeEnter: authGuard,
        meta: {
          layout: 'auth-layout',
          roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
        }
      },
      {
        path: 'templates',
        name: 'templates',
        component: () => import('@/views/Settings/Templates'),
        beforeEnter: authGuard,
        meta: {
          layout: 'auth-layout',
          roles: [userRole.superAdmin, userRole.admin, userRole.chainAdmin]
        }
      }
    ]
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: () => import('@/views/AccessDenied'),
    beforeEnter: authGuard,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/no-chain',
    name: 'noChain',
    component: () => import('@/views/NoChain')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/Error')
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: () => import('@/views/NotFound')
  },
  {
    path: '/under-maintenance',
    name: 'underMaintenance',
    component: () => import('@/views/UnderMaintenance')
  },
  {
    path: '*',
    redirect: { name: 'notFound' }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
