<template>
  <v-dialog
    :value="dialog"
    :max-width="width"
    @keydown.enter="submit(true)"
    @keydown.esc="submit(false)"
    @click:outside="submit(false)"
  >
    <v-card
      :class="{ 'pt-2': !title }"
      outlined
    >
      <template v-if="title">
        <v-card-title
          v-html="title"
          class="py-2"
        ></v-card-title>
        <v-divider></v-divider>
      </template>
      <v-card-text
        v-html="message"
        class="py-4 body-1 text--primary"
      ></v-card-text>
      <v-card-actions class="justify-center pb-3">
        <v-btn
          :color="falseColor"
          text
          @click.stop.prevent="submit(false)"
        >
          {{ falseText || $t('components.confirm.falseText') }}
        </v-btn>
        <v-btn
          :color="trueColor"
          text
          @click.stop.prevent="submit(true)"
        >
          {{ trueText || $t('components.confirm.trueText') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { VDialog, VBtn, VCard, VCardText, VCardActions } from 'vuetify/lib'


export default {
  components: {
    VDialog,
    VBtn,
    VCard,
    VCardText,
    VCardActions
  },



  props: {
    trueText: {
      type: String
    },
    falseText: {
      type: String
    },
    trueColor: {
      type: String,
      default: 'primary'
    },
    falseColor: {
      type: String,
      default: 'secondary'
    },
    title: {
      type: String
    },
    message: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 400
    }
  },


  data () {
    return {
      value: false,
      dialog: true
    }
  },


  methods: {
    submit (value) {
      this.$emit('result', value)
      this.value = value
      this.dialog = false
      this.$destroy()
    }
  }
}
</script>
