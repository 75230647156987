import axios from 'axios'
import vueApp from '@/main'


const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})


apiClient.interceptors.request.use(async (config) => {
  if (config.auth !== false) {
    try {
      const token = await vueApp.$auth.getTokenSilently()
      config.headers.Authorization = `Bearer ${token}`
    } catch (error) {
      vueApp.$auth.loginWithRedirect({ appState: { targetUrl: window.location.pathname } })
      return Promise.reject(error)
    }
  }

  return config
}, (error) => {
  return Promise.reject(error)
})


apiClient.interceptors.request.use(async (config) => {
  if (typeof config.data === 'object') {
    try {
      for (const prop in config.data) {
        if (config.data[prop] === '') {
          config.data[prop] = null
        }
      }
    } catch (error) {
      vueApp.$log.error(error)
      return Promise.reject(error)
    }
  }

  return config
}, (error) => {
  return Promise.reject(error)
})


export default apiClient
