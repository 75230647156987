import apiClient from './apiClient'


const getServices = (vehicleId) => {
  return apiClient.get(`/Service/${vehicleId}`)
}

const getServicesByChainIdForUser = (chainId, skip, take, search) => {
  const params = { skip, take, search }
  return apiClient.get(`/Service/${chainId}/user`, { params })
}

const getService = (vehicleId, id) => {
  return apiClient.get(`/Service/${vehicleId}/${id}`)
}

const createService = (vehicleId, service) => {
  return apiClient.post(`/Service/${vehicleId}`, service)
}

const updateService = (vehicleId, id, service) => {
  return apiClient.put(`/Service/${vehicleId}/${id}`, service)
}

const deleteService = (vehicleId, id) => {
  return apiClient.delete(`/Service/${vehicleId}/${id}`)
}


export default {
  getServices,
  getServicesByChainIdForUser,
  getService,
  createService,
  updateService,
  deleteService
}
