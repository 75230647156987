import { en, no } from 'vuetify/es5/locale'

import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/nb'


const DEFAULT_LOCALE = 'no'

const FALLBACK_LOCALE = 'en'

const LOCALES = [
  {
    locale: 'en',
    momentLocale: 'en-gb',
    language: 'English'
  },
  {
    locale: 'no',
    momentLocale: 'nb',
    language: 'Norsk bokmål'
  }
]


const config = {
  initialLocale: localStorage.getItem('locale') || DEFAULT_LOCALE || 'en',
  fallbackLocale: FALLBACK_LOCALE || 'en',
  locales: LOCALES,
  vuetifyLocaleMessages: { en, no },

  setLocale (locale) {
    const momentLocale = LOCALES.find(x => x.locale === locale)?.momentLocale || DEFAULT_LOCALE
    moment.locale(momentLocale)
    localStorage.setItem('locale', locale)
  },

  loadLocaleMessages () {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }
}

config.setLocale(config.initialLocale)



export default config
