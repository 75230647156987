import Vue from 'vue'
import serviceTypeApi from '@/api/serviceType.api'


const state = {
  serviceTypes: {}
}


const getters = {
  serviceTypes: (state) => {
    return Object.values(state.serviceTypes)
  },

  serviceTypeById: (state) => (id) => {
    return state.serviceTypes[id]
  }
}


const actions = {
  async loadServiceTypes ({ commit }) {
    const response = await serviceTypeApi.getServiceTypes()
    const items = response.data
    commit('SET_SERVICE_TYPES', items)
    return items
  },

  async createServiceType ({ commit }, serviceType) {
    const response = await serviceTypeApi.createServiceType(serviceType)
    commit('CREATE_SERVICE_TYPE', response.data)
  },

  async updateServiceType ({ commit }, serviceType) {
    const response = await serviceTypeApi.updateServiceType(serviceType.id, serviceType)
    commit('UPDATE_SERVICE_TYPE', response.data)
  },

  async deleteServiceType ({ commit }, id) {
    await serviceTypeApi.deleteServiceType(id)
    commit('DELETE_SERVICE_TYPE', id)
  }
}


const mutations = {
  SET_SERVICE_TYPES (state, items) {
    state.serviceType = {}

    for (const serviceType of items) {
      Vue.set(state.serviceTypes, serviceType.id, serviceType)
    }
  },

  CREATE_SERVICE_TYPE (state, serviceType) {
    Vue.set(state.serviceTypes, serviceType.id, serviceType)
  },

  UPDATE_SERVICE_TYPE (state, serviceType) {
    Vue.set(state.serviceTypes, serviceType.id, serviceType)
  },

  DELETE_SERVICE_TYPE (state, id) {
    Vue.delete(state.serviceTypes, id)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
