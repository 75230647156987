import Vue from 'vue'
import reportApi from '@/api/report.api'


const state = {
  activeCarsReport: {},
  nextNotificationsReport: {},
  notificationsSentReport: {},
  servicesReport: {},
  servicesByDealerReport: {}
}


const getters = {
  activeCarsReport: (state) => (dealerId) => {
    return state.activeCarsReport[dealerId] || []
  },

  nextNotificationsReport: (state) => (dealerId) => {
    return state.nextNotificationsReport[dealerId] || []
  },

  notificationsSentReport: (state) => (dealerId) => {
    return state.notificationsSentReport[dealerId] || []
  },

  servicesReport: (state) => (dealerId, initialTreatment) => {
    return state.servicesReport[dealerId + initialTreatment] || []
  },

  servicesByDealerReport: (state) => (initialTreatment) => {
    return state.servicesByDealerReport[initialTreatment] || []
  }
}


const actions = {
  async loadActiveCarsReport ({ commit }, { dealerId, chainId }) {
    const response = await reportApi.getActiveCarsReport(dealerId, chainId)
    const items = response.data
    commit('SET_ACTIVE_CARS_REPORT', { dealerId, items })
    return items
  },

  async loadNextNotificationsReport ({ commit }, { dealerId, chainId }) {
    const response = await reportApi.getNextNotificationsReport(dealerId, chainId)
    const items = response.data
    commit('SET_NEXT_NOTIFICATIONS_REPORT', { dealerId, items })
    return items
  },

  async loadNotificationsSentReport ({ commit }, { dealerId, chainId }) {
    const response = await reportApi.getNotificationsSentReport(dealerId, chainId)
    const items = response.data
    commit('SET_NOTIFICATIONS_SENT_REPORT', { dealerId, items })
    return items
  },

  async loadServicesReport ({ commit }, { dealerId, initialTreatment, chainId }) {
    const response = await reportApi.getServicesReport(initialTreatment, dealerId, false, chainId)
    const items = response.data
    commit('SET_SERVICES_REPORT', { dealerId, initialTreatment, items })
    return items
  },

  async loadServicesByDealerReport ({ commit }, { chainId, initialTreatment }) {
    const response = await reportApi.getServicesReport(initialTreatment, '', true, chainId)
    const items = response.data
    commit('SET_SERVICES_BY_DEALER_REPORT', { initialTreatment, items })
    return items
  }
}


const mutations = {
  SET_ACTIVE_CARS_REPORT (state, { dealerId, items }) {
    Vue.set(state.activeCarsReport, dealerId, items)
  },

  SET_NEXT_NOTIFICATIONS_REPORT (state, { dealerId, items }) {
    Vue.set(state.nextNotificationsReport, dealerId, items)
  },

  SET_NOTIFICATIONS_SENT_REPORT (state, { dealerId, items }) {
    Vue.set(state.notificationsSentReport, dealerId, items)
  },

  SET_SERVICES_REPORT (state, { dealerId, initialTreatment, items }) {
    Vue.set(state.servicesReport, dealerId + initialTreatment, items)
  },

  SET_SERVICES_BY_DEALER_REPORT (state, { initialTreatment, items }) {
    Vue.set(state.servicesByDealerReport, initialTreatment, items)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
