export default {
  getObjectCopy (obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  arrayToObject (array, prop = 'id') {
    const obj = {}

    for (const item of array) {
      obj[item[prop]] = item
    }

    return obj
  }
}
