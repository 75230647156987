import Vue from 'vue'
import dealerApi from '@/api/dealer.api'
import objectUtil from '@/utils/object.util'


const state = {
  currentDealerId: null,
  previousDealerId: null,
  shortDealers: {},
  shortDealersCurrentChain: {},
  initialTableItems: {},
  tableItems: {},
  totalCount: 0
}


const getters = {
  currentDealerId: (state) => {
    return state.currentDealerId
  },

  previousDealerId: (state) => {
    return state.previousDealerId
  },

  shortDealers: (state) => {
    return Object.values(state.shortDealers) || []
  },

  shortDealersCurrentChain: (state) => {
    return Object.values(state.shortDealersCurrentChain) || []
  },

  shortDealersSelectItems: (state) => {
    const shortDealersArray = Object.values(state.shortDealers) || []
    const shortDealerSelectItems = shortDealersArray.map(dealer => {
      return {
        text: dealer.name,
        value: dealer.id,
        disabled: !!dealer.chainId
      }
    })
    return shortDealerSelectItems
  },

  shortDealerById: (state) => (id) => {
    return state.shortDealers[id] || state.shortDealersCurrentChain[id]
  },

  dealersTableItems: (state) => {
    return Object.values(state.tableItems)
  },

  dealersTotalCount: (state) => {
    return state.totalCount
  }
}


const actions = {
  async loadShortDealers ({ commit }) {
    const response = await dealerApi.getShortDealers()
    commit('SET_SHORT_DEALERS', response.data)
    return response.data
  },

  async loadShortDealersByChainId ({ commit }, chainId) {
    const response = await dealerApi.getShortDealersByChainId(chainId)
    commit('SET_SHORT_DEALERS_CURRENT_CHAIN', response.data)
    return response.data
  },

  async loadDealersTableItems ({ commit }, { search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await dealerApi.getDealers(skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', items)
    commit('SET_TOTAL_COUNT', { totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', items)
    }

    return items
  },

  async loadDealersTableItemsByChainId ({ commit }, { chainId, search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await dealerApi.getDealersByChainId(chainId, skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', items)
    commit('SET_TOTAL_COUNT', { totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', items)
    }

    return items
  },

  restoreInitialDealersTableItems ({ commit }) {
    if (state.initialTableItems) {
      commit('SET_TABLE_ITEMS', state.initialTableItems)
    }
  },

  async loadDealerById ({ commit }, { chainId, id }) {
    const response = await dealerApi.getDealer(chainId, id)
    commit('UPDATE_DEALER', response.data)
    return response.data
  },

  async createDealer ({ commit }, dealer) {
    const response = await dealerApi.createDealer(dealer)
    commit('CREATE_DEALER', response.data)
    commit('SET_TOTAL_COUNT', { shift: 1 })
  },

  async createDealerWithChainId ({ commit }, { dealer, chainId }) {
    const response = await dealerApi.createDealerWithChainId(dealer, chainId)
    commit('CREATE_DEALER', response.data)
    commit('SET_TOTAL_COUNT', { shift: 1 })
  },

  async updateDealer ({ commit }, dealer) {
    const response = await dealerApi.updateDealer(dealer.id, dealer)
    commit('UPDATE_DEALER', response.data)
  },

  async updateDealerWithChainId ({ commit }, { dealer, chainId }) {
    const response = await dealerApi.updateDealerWithChainId(dealer, chainId)
    commit('UPDATE_DEALER', response.data)
  },

  async deleteDealer ({ commit }, id) {
    await dealerApi.deleteDealer(id)
    commit('DELETE_DEALER', id)
    commit('SET_TOTAL_COUNT', { shift: -1 })
  },

  setCurrentDealerId ({ commit }, id) {
    commit('SET_CURRENT_DEALER_ID', id)
    localStorage.setItem('currentDealerId', id)
  },

  restoreCurrentDealerId ({ commit }) {
    const currentDealerId = localStorage.getItem('currentDealerId')
    commit('SET_CURRENT_DEALER_ID', currentDealerId)
    return currentDealerId
  }
}


const mutations = {
  SET_SHORT_DEALERS_CURRENT_CHAIN (state, items) {
    state.shortDealersCurrentChain = objectUtil.arrayToObject(items)
  },

  SET_SHORT_DEALERS (state, items) {
    state.shortDealers = objectUtil.arrayToObject(items)
  },

  SET_CURRENT_DEALER_ID (state, id) {
    state.previousDealerId = state.currentDealerId
    state.currentDealerId = id
  },

  SET_TABLE_ITEMS (state, items) {
    state.tableItems = Array.isArray(items) ? objectUtil.arrayToObject(items) : items

    for (const { id, name } of Object.values(state.tableItems)) {
      Vue.set(state.shortDealers, id, { id, name })
    }
  },

  SET_INITIAL_TABLE_ITEMS (state, items) {
    state.initialTableItems = objectUtil.arrayToObject(items)
  },

  SET_TOTAL_COUNT (state, { totalCount, shift }) {
    if (totalCount !== undefined) {
      state.totalCount = Math.max(0, totalCount)
    }
    if (shift) {
      const shifted = (state.totalCount || 0) + shift
      state.totalCount = Math.max(0, shifted)
    }
  },

  CREATE_DEALER (state, dealer) {
    Vue.set(state.tableItems, dealer.id, dealer)
    Vue.set(state.shortDealers, dealer.id, { id: dealer.id, name: dealer.name })
  },

  UPDATE_DEALER (state, dealer) {
    if (state.tableItems[dealer.id]) {
      Vue.set(state.tableItems, dealer.id, dealer)
    }
    if (state.initialTableItems[dealer.id]) {
      Vue.set(state.initialTableItems, dealer.id, dealer)
    }
    Vue.set(state.shortDealers, dealer.id, { id: dealer.id, name: dealer.name })
  },

  DELETE_DEALER (state, id) {
    Vue.delete(state.tableItems, id)
    Vue.delete(state.initialTableItems, id)
    Vue.delete(state.shortDealers, id)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
