import apiClient from './apiClient'


const getActiveCarsReport = (dealerId /* optional */, chainId /* optional */) => {
  if (dealerId) {
    return apiClient.get(`/Report/ActiveCars/${dealerId}`)
  } else if (chainId) {
    return apiClient.get(`/Report/${chainId}/ActiveCars`)
  } else {
    return apiClient.get('/Report/ActiveCars')
  }
}

const getNotificationsSentReport = (dealerId /* optional */, chainId /* optional */) => {
  if (dealerId) {
    return apiClient.get(`/Report/NotificationsSent/${dealerId}`)
  } else if (chainId) {
    return apiClient.get(`/Report/${chainId}/NotificationsSent`)
  } else {
    return apiClient.get('/Report/NotificationsSent')
  }
}

const getNextNotificationsReport = (dealerId /* optional */, chainId /* optional */) => {
  if (dealerId) {
    return apiClient.get(`/Report/NextNotifications/${dealerId}`)
  } else if (chainId) {
    return apiClient.get(`/Report/${chainId}/NextNotifications`)
  } else {
    return apiClient.get('/Report/NextNotifications')
  }
}

const getServicesReport = (initialTreatment, dealerId /* optional */, byDealer, /* optional */ chainId) => {
  const params = { byDealer }
  if (dealerId) {
    return apiClient.get(`/Report/Services/${initialTreatment}/${dealerId}`, { params })
  } else if (chainId) {
    return apiClient.get(`/Report/${chainId}/Services/${initialTreatment}`, { params })
  } else {
    return apiClient.get(`/Report/Services/${initialTreatment}`)
  }
}

export default {
  getActiveCarsReport,
  getNotificationsSentReport,
  getNextNotificationsReport,
  getServicesReport
}
