import apiClient from './apiClient'


const getVehicles = (dealerId /* optional */, chainId, skip, take, search) => {
  const params = { skip, take, search }
  if (dealerId) {
    return apiClient.get(`/Vehicle/dealer/${dealerId}`, { params })
  } else {
    return apiClient.get(`/Vehicle/${chainId}`, { params })
  }
}

const getVehicleByLicenseNumber = (licenseNumber, chainId) => {
  return apiClient.get(`/Vehicle/${chainId}/LicenseNumber/${licenseNumber}`)
}

const createVehicle = (dealerId, vehicle) => {
  return apiClient.post(`/Vehicle/${dealerId}`, vehicle)
}

const updateVehicle = (dealerId, id, vehicle) => {
  return apiClient.put(`/Vehicle/${dealerId}/${id}`, vehicle)
}

const moveVehicle = (oldDealerId, newDealerId, id, vehicle, chainId) => {
  return apiClient.put(`/Vehicle/${chainId}/${oldDealerId}/${newDealerId}/${id}`, vehicle)
}

const deleteVehicle = (dealerId, id) => {
  return apiClient.delete(`/Vehicle/${dealerId}/${id}`)
}


const getVehicleBrands = () => {
  return apiClient.get('/VehicleBrand')
}

const getVehicleModels = (brand) => {
  return apiClient.get(`/VehicleModel/${brand}`)
}


export default {
  getVehicles,
  getVehicleByLicenseNumber,
  createVehicle,
  updateVehicle,
  moveVehicle,
  deleteVehicle,
  getVehicleBrands,
  getVehicleModels
}
