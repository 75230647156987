import Vue from 'vue'
import App from './App.vue'
import appHooks from './app.hooks'
import router from './router'
import store from './store'

import { Auth0Plugin } from '@/plugins/auth0'
import vuetify from '@/plugins/vuetify'
import logger from '@/plugins/logger'
import i18n from '@/plugins/i18n'
import alert from '@/plugins/alert'
import confirm from '@/plugins/confirm'

import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'


console.info(process.env.NODE_ENV)
const isDev = process.env.NODE_ENV === 'development'
Vue.config.performance = isDev
Vue.config.productionTip = false


Vue.use(logger)
Vue.use(alert, { vuetify, i18n })
Vue.use(confirm, { vuetify, i18n })
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  cacheLocation: 'localstorage',
  onRedirectCallback: appHooks.authCallback,
  onLoaded: appHooks.authLoaded
})


Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)


const vueApp = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  beforeCreate: appHooks.beforeCreate
}).$mount('#app')


export default vueApp
