
export const dateTimeFormats = {
  datePicker: 'YYYY-MM-DD',
  displayDate: 'll',
  displayDateTime: 'lll',
  dateUtc: 'YYYY-MM-DDT00:00:00',
  dateTimeUtc: 'YYYY-MM-DDTHH:mm:ss'
}

export const nextNotificationDateSettings = {
  emptyAfterMonths: 46, // propose to empty the date after this period since the last initial treatment (due to guarantee expiration)
  suggestionAfterMonths: 10, // suggest the next date not earlier than this period
  suggestionSkipDates: ['01.01', '02.01', '01.05', '17.05', '24.12', '25.12', '26.12', '27.12', '28.12', '29.12', '30.12', '31.12'],
  suggestionSkipWeekDays: [0, 6] // moment.js weekdays
}


/* Enums
----------------------------------- */
export const userRole = {
  superAdmin: 1,
  admin: 2,
  user: 3,
  chainAdmin: 4,
  salesPerson: 5
}

export const notificationType = {
  email: 1,
  sms: 2
}

export const templateType = {
  bookingConfirmation: 1,
  guaranteeNotification: 2
}


/* Entities schemes
----------------------------------- */
export const chainSchema = {
  nameMaxLength: 50
}

export const dealerSchema = {
  nameMaxLength: 50,
  phoneMaxLength: 50,
  emailMaxLength: 50,
  urlMaxLength: 250
}

export const vehicleSchema = {
  licenseNumberMaxLength: 10,
  brandMaxLength: 50,
  modelMaxLength: 50,
  firstNameMaxLength: 50,
  lastNameMaxLength: 50,
  phoneMaxLength: 50,
  emailMaxLength: 50,
  addressMaxLength: 250,
  areaMaxLength: 50,
  zipCodeMaxLength: 4,
  commentMaxLength: 1000
}

export const serviceSchema = {
  commentMaxLength: 1000
}

export const serviceTypeSchema = {
  nameMaxLength: 50
}

export const templateSchema = {
  subjectMaxLength: 250,
  smsTemplateMaxLength: 400,
  smsMaxLength: 500
}


/* Pages settings
----------------------------------- */
export const dealersPageSize = 50
export const vehiclesPageSize = 50
export const chainsPageSize = 50
export const usersPageSize = 50
export const servicesPageSize = 50
export const notificationsPageSize = 50
export const serviceTypesPageSize = 50
