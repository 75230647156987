import router from '@/router'

export default {
  isRouteAvailableForRole (routeName, role) {
    const route = router.resolve({ name: routeName }).route

    if (!route) return

    const routeRoles = route.meta.roles

    if (!routeRoles || !routeRoles.length || routeRoles.includes(role)) {
      return true
    }

    return false
  }
}
