import Vue from 'vue'
import vehicleApi from '@/api/vehicle.api'
import objectUtil from '@/utils/object.util'


const state = {
  initialTableItems: {},
  tableItems: {},
  totalCount: {},
  brands: [],
  models: {}
}


const getters = {
  vehiclesTableItems: (state) => (dealerId) => {
    return Object.values(state.tableItems[dealerId] || {})
  },

  vehiclesTotalCount: (state) => (dealerId) => {
    return state.totalCount[dealerId] || 0
  },

  vehicleBrands: (state) => {
    return state.brands
  },

  vehicleModels: (state) => (brand) => {
    if (!brand) return []
    brand = brand.trim().toUpperCase()
    return state.models[brand.toUpperCase()] || []
  }
}


const actions = {
  async loadVehiclesTableItems ({ commit }, { dealerId, chainId, search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await vehicleApi.getVehicles(dealerId, chainId, skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', { dealerId, items })
    commit('SET_TOTAL_COUNT', { dealerId, totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', { dealerId, items })
    }

    return items
  },

  restoreInitialVehiclesTableItems ({ commit, state }, dealerId) {
    if (dealerId !== null && state.initialTableItems[dealerId]) {
      commit('SET_TABLE_ITEMS', { dealerId, items: state.initialTableItems[dealerId] })
    }
  },

  async getVehicleByLicenseNumber ({ commit }, { licenseNumber, chainId }) {
    const response = await vehicleApi.getVehicleByLicenseNumber(licenseNumber, chainId)
    return response.data
  },

  async createVehicle ({ commit }, vehicle) {
    const dealerId = vehicle.dealerId
    const response = await vehicleApi.createVehicle(dealerId, vehicle)
    commit('CREATE_VEHICLE', response.data)
    commit('SET_TOTAL_COUNT', { dealerId, shift: 1 })
    return response.data
  },

  async updateVehicle ({ commit }, vehicle) {
    const response = await vehicleApi.updateVehicle(vehicle.dealerId, vehicle.id, vehicle)
    commit('UPDATE_VEHICLE', response.data)
  },

  async moveVehicle ({ commit }, { dealerId, vehicle, chainId }) {
    const newDealerId = dealerId
    const oldDealerId = vehicle.dealerId
    const response = await vehicleApi.moveVehicle(oldDealerId, newDealerId, vehicle.id, vehicle, chainId)
    commit('UPDATE_VEHICLE', response.data)
    return response.data
  },

  async deleteVehicle ({ commit }, { dealerId, id }) {
    await vehicleApi.deleteVehicle(dealerId, id)
    commit('DELETE_VEHICLE', { dealerId, id })
    commit('SET_TOTAL_COUNT', { dealerId, shift: -1 })
  },


  async loadVehicleBrands ({ commit }) {
    const response = await vehicleApi.getVehicleBrands()
    const items = response.data
    commit('SET_BRANDS', items)
    return items
  },

  async loadVehicleModels ({ commit }, brand) {
    brand = brand.trim().toUpperCase()
    const response = await vehicleApi.getVehicleModels(brand)
    const items = response.data
    commit('SET_MODELS', { brand, models: items })
    return items
  }
}


const mutations = {
  SET_TABLE_ITEMS (state, { dealerId, items }) {
    const itemsObject = Array.isArray(items) ? objectUtil.arrayToObject(items) : items
    Vue.set(state.tableItems, dealerId, itemsObject)
  },

  SET_INITIAL_TABLE_ITEMS (state, { dealerId, items }) {
    Vue.set(state.initialTableItems, dealerId, objectUtil.arrayToObject(items))
  },

  SET_TOTAL_COUNT (state, { dealerId, totalCount, shift }) {
    if (totalCount !== undefined) {
      Vue.set(state.totalCount, dealerId, Math.max(0, totalCount))
    }
    if (shift) {
      const shifted = (state.totalCount[dealerId] || 0) + shift
      Vue.set(state.totalCount, dealerId, Math.max(0, shifted))
    }
  },

  CREATE_VEHICLE (state, vehicle) {
    const dealerId = vehicle.dealerId

    if (!state.tableItems[dealerId]) {
      Vue.set(state.tableItems, dealerId, {})
    }
    Vue.set(state.tableItems[dealerId], vehicle.id, vehicle)
  },

  UPDATE_VEHICLE (state, vehicle) {
    const dealerId = vehicle.dealerId

    if (state.tableItems[dealerId] && state.tableItems[dealerId][vehicle.id]) {
      Vue.set(state.tableItems[dealerId], vehicle.id, vehicle)
    }
    if (state.tableItems[''] && state.tableItems[''][vehicle.id]) {
      Vue.set(state.initialTableItems[''], vehicle.id, vehicle)
    }
  },

  DELETE_VEHICLE (state, { dealerId, id }) {
    if (state.tableItems[dealerId]) {
      Vue.delete(state.tableItems[dealerId], id)
    }
    if (state.tableItems['']) {
      Vue.delete(state.initialTableItems[''], id)
    }
  },


  SET_BRANDS (state, brands) {
    state.brands = brands
  },

  SET_MODELS (state, { brand, models }) {
    Vue.set(state.models, brand, models)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
