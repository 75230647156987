<template>
  <div class="d-auth-layout">
    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      class="d-app-nav"
      app
      dark
    >
      <v-sheet class="d-logo-wrapper">
        <router-link class="d-logo" :to="{ name: 'home' }"></router-link>
      </v-sheet>
      <v-list nav class="px-3 mt-10">
        <v-list-item
          v-for="item in drawerItems"
          :key="item.title"
          :to="{ name: item.route }"
          :ripple="false"
          :disabled="item.disabled"
          link
        >
          <v-list-item-icon>
            <v-icon size="22">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-app-bar
        app
        class="d-page-wrapper"
        flat
      >
        <v-app-bar-nav-icon
          class="d-lg-none mr-2"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <div class="d-page-title">
          {{ pageTitle }}
        </div>

        <v-spacer></v-spacer>

        <template v-if="!['chains', 'dealers'].includes(currentRouteName)">
<!--          <template v-if="['reports'].includes(currentRouteName)">-->
<!--            <v-checkbox-->
<!--              v-model="isAllChains"-->
<!--              class="mt-6 mr-10 font-weight-bold"-->
<!--            >-->
<!--              <template v-slot:label>-->
<!--                <span class="text&#45;&#45;primary">-->
<!--                  {{ $t('dealer.selectOptionAllDealersAllChains') }}-->
<!--                </span>-->
<!--              </template>-->
<!--            </v-checkbox>-->
<!--          </template>-->
          <v-autocomplete
            v-if=" currentUser.roleId === userRole.superAdmin || currentUser.roleId === userRole.chainAdmin"
            :value="currentDealerId"
            :items="dealerSelectItems"
            item-value="id"
            item-text="name"
            :loading="dealersLoading"
            :class="{ 'd-dealer-select--active': dealerSelectActive }"
            class="d-dealer-select"
            style="max-width:360px"
            hide-details
            outlined
            dense
            @change="setCurrentDealerId"
          ></v-autocomplete>

          <div
            v-else
            v-text="(shortDealerById(currentDealerId) || {}).name"
            class="mr-8 font-weight-medium"
          ></div>
        </template>

        <d-user-menu></d-user-menu>
      </v-app-bar>

      <v-progress-linear
        :active="pageLoading"
        :indeterminate="true"
        height="2"
        class="d-page-progress"
      ></v-progress-linear>
      <slot v-if="created" />
    </v-main>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { userRole } from '@/constants'

import routerUtil from '@/utils/router.util'

import snackbarMixin from '@/mixins/snackbar.mixin'

import DUserMenu from '@/components/DUserMenu'


export default {
  components: {
    DUserMenu
  },


  mixins: [
    snackbarMixin
  ],


  data () {
    return {
      drawer: true,
      created: false,
      dealersLoading: false,
      chainsLoading: false,
      userRole,
      dealerSelectItems: []
    }
  },


  computed: {
    ...mapState('pageSettings', ['pageTitle', 'pageLoading']),
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('dealer', ['currentDealerId', 'shortDealersCurrentChain', 'shortDealerById']),
    ...mapGetters('chain', ['currentChainId', 'chains', 'currentChain']),


    currentRouteName () {
      return this.$route.name
    },

    drawerItems () {
      const allItems = [
        { title: this.$t('nav.services'), icon: 'mdi-basket', route: 'services' },
        { title: this.$t('nav.chains'), icon: 'mdi-link-variant', route: 'chains' },
        { title: this.$t('nav.vehicles'), icon: 'mdi-car-settings', route: 'vehicles' },
        // { title: this.$t('nav.booking'), icon: 'mdi-book-open-variant', route: 'booking', disabled: true },
        { title: this.$t('nav.reports'), icon: 'mdi-card-account-details-outline', route: 'reports' },
        { title: this.$t('nav.users'), icon: 'mdi-account-group', route: 'users' },
        { title: this.$t('nav.dealers'), icon: 'mdi-account-cash', route: 'dealers' },
        { title: this.$t('nav.notifications'), icon: 'mdi-flag', route: 'notifications' },
        { title: this.$t('nav.settings'), icon: 'mdi-cog', route: 'settings' }
      ]

      const items = allItems.filter(item => routerUtil.isRouteAvailableForRole(item.route, this.currentUser.roleId))

      return items
    },

    chainSelectItems () {
      return this.chains
    },

    // dealerSelectItems () {
    //   console.info('dealerSelectItems ----------->')
    //   const items = this.shortDealersCurrentChain
    //   console.info('items === ', items)
    //
    //   if (this.currentUser.roleId === userRole.superAdmin || this.currentUser.roleId === userRole.chainAdmin) {
    //     items.unshift({ id: '', name: this.$t('dealer.selectOptionAll') })
    //     console.info('this.currentRouteName === ', this.currentRouteName)
    //     console.info('test = ', ['reports'].includes(this.currentRouteName))
    //     console.info('items === ', items)
    //
    //     if (['reports'].includes(this.currentRouteName)) {
    //       items.unshift({ id: 'all', name: this.$t('dealer.selectOptionAllDealersAllChains') })
    //       console.info('items === ', items)
    //     }
    //   }
    //   return items
    // },

    dealerSelectActive () {
      var dealerDependentRoutes = [
        'vehicles',
        'notifications',
        'users',
        'templates',
        'reports'
      ]

      return dealerDependentRoutes.includes(this.$route.name)
    }
  },


  methods: {
    ...mapActions('user', ['loadCurrentUser']),
    ...mapActions('dealer', ['loadShortDealers', 'loadShortDealersByChainId', 'setCurrentDealerId']),
    ...mapActions('chain', ['loadChains', 'setCurrentChainId']),

    initDealerSelectItems () {
      this.dealerSelectItems = [...this.shortDealersCurrentChain]
      if (this.currentUser.roleId === userRole.superAdmin || this.currentUser.roleId === userRole.chainAdmin) {
        this.dealerSelectItems.unshift({ id: '', name: this.$t('dealer.selectOptionAll') })
      }
      if (this.currentUser.roleId === userRole.chainAdmin) {
        if (['reports'].includes(this.currentRouteName)) {
          this.dealerSelectItems.unshift({ id: 'all', name: this.$t('dealer.selectOptionAllDealersAllChains') })
        }
      }

      if (!this.dealerSelectItems.map(item => item.id).includes(this.currentDealerId)) {
        this.setCurrentDealerId(this.dealerSelectItems[0]?.id)
      }
    }
  },

  watch: {
    currentRouteName () {
      this.initDealerSelectItems()
    }
  },

  async created () {
    try {
      if (this.currentUserIsChainAdmin) {
        this.dealersLoading = true
        await this.loadShortDealers()
        await this.loadChains()
        await this.loadShortDealersByChainId(this.currentUser.chainId)
        this.dealersLoading = false
      } else if (this.currentUser.chainId) {
        this.dealersLoading = true
        await this.loadShortDealersByChainId(this.currentUser.chainId)
        this.dealersLoading = false
      }
      this.initDealerSelectItems()
      this.created = true
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    }
  }
}
</script>
