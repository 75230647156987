import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nConfig from '@/i18n.config'


Vue.use(VueI18n)


export default new VueI18n({
  locale: i18nConfig.initialLocale,
  fallbackLocale: i18nConfig.fallbackLocale,
  messages: i18nConfig.loadLocaleMessages(),
  silentFallbackWarn: process.env.NODE_ENV !== 'development'
})
