import { getInstance } from '@/plugins/auth0'
import store from '@/store'
import { userRole } from '@/constants'


export const authGuard = (to, from, next) => {
  const authService = getInstance()


  const fn = () => {
    if (authService.isAuthenticated) {
      const user = store.state.user.currentUser

      if (user && user.isActive) {
        if (!user.disclaimerReadDateUtc && to.name !== 'disclaimer') {
          return next({ name: 'disclaimer' })
        }

        if (user.roleId !== 4 && !user.chainId) {
          return next({ name: 'noChain' })
        }

        const routeRoles = to.meta.roles
        if (!routeRoles || routeRoles.includes(user.roleId)) {
          return next()
        }

        if (to.name === 'vehicles') {
          if (user.roleId === userRole.salesPerson) {
            return next({ name: 'services' })
          }
          if (user.roleId === userRole.chainAdmin) {
            return next({ name: 'chains' })
          }
        }

        return next({ name: 'accessDenied' })
      }

      return next({ name: 'unauthorized' })
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }


  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
