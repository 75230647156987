export default {
  themes: {
    light: {
      primary: '#1A3167',
      secondary: '#757575',

      'd-orange': '#DD742C',
      'd-red': '#B51D1D',
      'd-green': '#72A246'
    }
  }
}
