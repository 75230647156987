<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    offset-y
    @input="onMenuInput"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar color="primary">
          <v-icon color="white">mdi-account</v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <v-card outlined>
      <v-list
        width="550"
        dense
      >
        <v-list-group
          v-if="currentUserIsChainAdmin"
          v-model="chainGroupExpanded"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $t('chainTitel') }}
            </v-list-item-content>
          </template>
          <template v-slot:appendIcon>
            <div class="secondary--text text-body-2 text-uppercase align-self-center">{{ currentChain.name }}</div>
          </template>

          <v-list-item
            v-for="chain in chains"
            :key="chain.name"
            @click="onChainClick(chain)"
          >
            <v-list-item-title>{{ chain.name }}</v-list-item-title>
            <v-list-item-icon >
              <v-icon v-if="chain.id === currentChainId" small color="primary">mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-model="languageGroupExpanded"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $t('language') }}
            </v-list-item-content>
          </template>
          <template v-slot:appendIcon>
            <div class="secondary--text text-body-2 text-uppercase align-self-center">{{ currentLocale }}</div>
          </template>

          <v-list-item
            v-for="{ locale, language } of locales"
            :key="locale"
            @click="onLanguageClick(locale)"
          >
            <v-list-item-title>{{ language }}</v-list-item-title>
            <v-list-item-icon >
              <v-icon v-if="locale === currentLocale" small color="primary">mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider class="my-2"></v-divider>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t('logout') }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
import authMixin from '@/mixins/auth.mixin'
import localeMixin from '@/mixins/locale.mixin'
import { mapGetters, mapActions } from 'vuex'


export default {
  mixins: [
    authMixin,
    localeMixin
  ],

  computed: {
    ...mapGetters('chain', ['chains', 'currentChainId', 'currentChain', 'chainById']),
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('dealer', ['shortDealersCurrentChain'])
  },

  data () {
    return {
      languageGroupExpanded: false,
      chainGroupExpanded: false
    }
  },

  methods: {
    ...mapActions('chain', ['setCurrentChainId']),
    ...mapActions('dealer', ['loadShortDealersByChainId', 'setCurrentDealerId']),

    onLanguageClick (locale) {
      this.setLocale(locale)
      this.languageGroupExpanded = false
    },

    async onChainClick (chain) {
      this.setCurrentChainId(chain.id)
      await this.loadShortDealersByChainId(this.currentChainId)
      // this.setCurrentDealerId(this.shortDealersCurrentChain[0].id)
      this.setCurrentDealerId('')
      this.chainGroupExpanded = false
    },

    onMenuInput () {
      this.languageGroupExpanded = false
      this.chainGroupExpanded = false
    }
  }
}
</script>
