const mixin = {
  computed: {
    isAuthenticated () {
      return this.$auth.isAuthenticated && !this.$auth.loading
    }
  },


  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
      localStorage.clear()
    }
  }
}


export default mixin
