import Vue from 'vue'
import vueApp from './main'
import store from './store'
import router from './router'
import { userRole } from '@/constants'


const beforeCreate = async () => {
  let user = await store.dispatch('user/restoreCurrentUser')
  if (!user) {
    user = await store.dispatch('user/loadCurrentUser')
  }
  const dealerId = await store.dispatch('dealer/restoreCurrentDealerId')
  const chainId = await store.dispatch('chain/restoreCurrentChainId')

  if (user && !dealerId) {
    if (dealerId === null || user.roleId !== userRole.superAdmin) {
      store.dispatch('dealer/setCurrentDealerId', user.dealerId)
    }
  }

  if (user.chainId && !chainId) {
    store.dispatch('chain/setCurrentChainId', user.chainId)
  }
}


const authCallback = async (appState) => {
  Vue.prototype.$redirectingFromAuth = true

  let redirectLocation = appState && appState.targetUrl
    ? appState.targetUrl
    : window.location.pathname
  try {
    const user = await store.dispatch('user/loadCurrentUser')
    await store.dispatch('dealer/setCurrentDealerId', user.dealerId)
  } catch (error) {
    if (error?.response?.status === 401) {
      redirectLocation = { name: 'unauthorized' }
    } else {
      redirectLocation = { name: 'error' }
      vueApp.$log.error(error)
    }
  }

  router.push(redirectLocation,
    () => {
      Vue.prototype.$redirectingFromAuth = false
    },
    () => {
      Vue.prototype.$redirectingFromAuth = false
    }
  )
}


const authLoaded = async () => {
  if (!vueApp.$auth.isAuthenticated || vueApp.$auth.redirected) return

  try {
    const user = await store.dispatch('user/loadCurrentUser')
    const dealerId = store.state.dealer.currentDealerId
    if (user && !dealerId) {
      if (dealerId === null || user.roleId !== userRole.superAdmin) {
        store.dispatch('dealer/setCurrentDealerId', user.dealerId)
      }
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      router.push({ name: 'unauthorized' })
    } else {
      vueApp.$log.error(error)
      router.push({ name: 'error' })
    }
  }
}


export default {
  authCallback,
  authLoaded,
  beforeCreate
}
