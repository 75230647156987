import Vue from 'vue'
import serviceApi from '@/api/service.api'
import objectUtil from '@/utils/object.util'


const state = {
  services: {},
  servicesForUser: {},
  totalCount: 0
}


const getters = {
  servicesByVehicle: (state) => (vehicleId) => {
    return Object.values(state.services).filter(x => x.vehicleId === vehicleId)
  },

  servicesForUser: (state) => {
    return Object.values(state.servicesForUser)
  },

  totalCount: (state) => {
    return state.totalCount
  }
}


const actions = {
  async loadServicesByVehicle ({ commit }, vehicleId) {
    const response = await serviceApi.getServices(vehicleId)
    const items = response.data
    commit('SET_SERVICES', { vehicleId, items })
    return items
  },

  async loadServicesByChainIdForUser ({ commit }, { chainId, search, pageNumber, pageSize }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await serviceApi.getServicesByChainIdForUser(chainId, skip, pageSize, search)

    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_SERVICES_FOR_USER', response.data)
    commit('SET_TOTAL_COUNT', totalCount)
  },

  async createService ({ commit }, service) {
    const response = await serviceApi.createService(service.vehicleId, service)
    commit('CREATE_SERVICE', response.data)
    return response.data
  },

  async updateService ({ commit }, service) {
    const response = await serviceApi.updateService(service.vehicleId, service.id, service)
    commit('UPDATE_SERVICE', response.data)
  },

  async deleteService ({ commit }, { vehicleId, id }) {
    await serviceApi.deleteService(vehicleId, id)
    commit('DELETE_SERVICE', id)
  }
}


const mutations = {
  SET_SERVICES (state, { vehicleId, items }) {
    for (const id in state.services) {
      if (state.services[id].vehicleId === vehicleId) {
        Vue.delete(state.services, id)
      }
    }

    for (const service of items) {
      Vue.set(state.services, service.id, service)
    }
  },

  SET_SERVICES_FOR_USER (state, items) {
    state.servicesForUser = objectUtil.arrayToObject(items)
  },

  SET_TOTAL_COUNT (state, totalCount) {
    state.totalCount = totalCount
  },

  CREATE_SERVICE (state, service) {
    Vue.set(state.services, service.id, service)
  },

  UPDATE_SERVICE (state, service) {
    Vue.set(state.services, service.id, service)
  },

  DELETE_SERVICE (state, id) {
    Vue.delete(state.services, id)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
