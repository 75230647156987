import Vue from 'vue'
import Rollbar from 'vue-rollbar'


Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  source_map_enabled: false,
  environment: process.env.NODE_ENV,
  payload: {
    client: {
      javascript: {
        code_version: process.env.VUE_APP_VERSION
      }
    }
  }
})


Vue.config.errorHandler = function (err, vm, info) {
  if (process.env.VUE_APP_LOGGER_CONSOLE_ENABLED === 'true') {
    console.error(err, vm, info)
  }
  Vue.rollbar.error(err, { info: info, componentTag: vm.$vnode?.componentOptions?.tag })
}


export default {
  install (Vue, options) {
    function error (error) {
      if (process.env.VUE_APP_LOGGER_CONSOLE_ENABLED === 'true') {
        console.error(error)
        if (error.response) {
          console.error(error.response)
        }
      }

      if (error.response) {
        Vue.rollbar.error(error, { response: JSON.stringify(error.response) })
      } else {
        Vue.rollbar.error(error)
      }
    }

    function info (message) {
      if (process.env.VUE_APP_LOGGER_CONSOLE_ENABLED === 'true') {
        console.info(message)
      }
      Vue.rollbar.info(message)
    }

    Vue.prototype.$log = {
      error,
      info
    }
  }
}
