import Vue from 'vue'
import Vuetify from 'vuetify'
import theme from '@/styles/theme'
import 'vuetify/dist/vuetify.min.css'
import i18nConfig from '@/i18n.config'


Vue.use(Vuetify)


const options = {
  theme,
  lang: {
    current: i18nConfig.initialLocale,
    locales: i18nConfig.vuetifyLocaleMessages
  }
}


export default new Vuetify(options)
