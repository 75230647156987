import apiClient from './apiClient'


const getDealer = (chainId, id) => {
  return apiClient.get(`/Dealer/${chainId}/${id}`)
}

const getDealers = (skip, take, search) => {
  const params = { skip, take, search }
  return apiClient.get('/Dealer', { params })
}

const getDealersByChainId = (chainId, skip, take, search) => {
  const params = { skip, take, search }
  return apiClient.get(`/Dealer/${chainId}`, { params })
}

const getShortDealers = () => {
  return apiClient.get('/Dealer/Short')
}

const getShortDealersByChainId = (chainId) => {
  return apiClient.get(`/Dealer/${chainId}/Short`)
}

const createDealerWithChainId = (dealer, chainId) => {
  return apiClient.post(`/Dealer/${chainId}`, dealer)
}

const createDealer = (dealer) => {
  return apiClient.post('/Dealer/', dealer)
}

const updateDealer = (id, dealer) => {
  return apiClient.put(`/Dealer/${id}`, dealer)
}

const updateDealerWithChainId = (dealer, chainId) => {
  return apiClient.put(`/Dealer/${chainId}/${dealer.id}`, dealer)
}

const deleteDealer = (id) => {
  return apiClient.delete(`/Dealer/${id}`)
}


export default {
  getDealer,
  getDealers,
  getDealersByChainId,
  getShortDealers,
  getShortDealersByChainId,
  createDealer,
  createDealerWithChainId,
  updateDealer,
  updateDealerWithChainId,
  deleteDealer
}
