
const state = {
  snackbar: false,
  snackbarText: ''
}


const actions = {
  setSnackbar ({ commit }, { text, timeout }) {
    commit('SET_SNACKBAR', { text, timeout })
  }
}


const mutations = {
  SET_SNACKBAR (state, { text, timeout }) {
    state.snackbarText = text
    state.snackbar = !!text
    if (state.snackbar && timeout !== 0) {
      setTimeout(() => { state.snackbar = false }, timeout)
    }
  }
}


export default {
  state,
  actions,
  mutations
}
