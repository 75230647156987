
const mixin = {
  methods: {
    showSnackbar (text, timeout) {
      this.$store.dispatch('snackbar/setSnackbar', { text, timeout })
    },

    hideSnackbar () {
      this.$store.dispatch('snackbar/setSnackbar', { text: '' })
    },

    showSnackbarProcessing (text, timeout) {
      timeout = timeout || 0
      text = text || this.$t('components.snackbar.processing')
      this.showSnackbar(text, timeout)
    },

    showSnackbarError (text, timeout) {
      timeout = timeout || 3000
      text = text || this.$t('components.snackbar.error')
      this.showSnackbar(text, timeout)
    },

    showSnackbarSuccess (text, timeout) {
      timeout = timeout || 3000
      text = text || this.$t('components.snackbar.success')
      this.showSnackbar(text, timeout)
    }
  }
}


export default mixin
