
const state = {
  pageTitle: '',
  pageLoading: false
}


const actions = {
  setPageTitle ({ commit }, title) {
    commit('SET_PAGE_TITLE', title)
  },

  setPageLoading ({ commit }, isLoading) {
    commit('SET_PAGE_LOADING', isLoading)
  }
}


const mutations = {
  SET_PAGE_TITLE (state, title) {
    state.pageTitle = title
  },

  SET_PAGE_LOADING (state, isLoading) {
    state.pageLoading = isLoading
  }
}


export default {
  state,
  actions,
  mutations
}
