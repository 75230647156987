import apiClient from './apiClient'


const getChains = (skip, take, search) => {
  const params = { skip, take, search }
  return apiClient.get('/Chain', { params })
}

const getChainById = (id) => {
  return apiClient.get(`/Chain/${id}`)
}

const createChain = (chain) => {
  return apiClient.post('/Chain', chain)
}

const updateChain = (id, chain) => {
  return apiClient.put(`/Chain/${id}`, chain)
}

const deleteChain = (id) => {
  return apiClient.delete(`/Chain/${id}`)
}


export default {
  getChains,
  getChainById,
  createChain,
  updateChain,
  deleteChain
}
