import apiClient from './apiClient'


const getServiceTypes = () => {
  return apiClient.get('/ServiceType')
}

const createServiceType = (serviceType) => {
  return apiClient.post('/ServiceType', serviceType)
}

const updateServiceType = (id, serviceType) => {
  return apiClient.put(`/ServiceType/${id}`, serviceType)
}

const deleteServiceType = (id) => {
  return apiClient.delete(`/ServiceType/${id}`)
}


export default {
  getServiceTypes,
  createServiceType,
  updateServiceType,
  deleteServiceType
}
