<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>

    <v-snackbar
      :value="snackbar"
      :timeout="-1"
      color="rgba(0, 0, 0, 0.75)"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>


<script>
import { mapState } from 'vuex'


export default {
  name: 'App',


  computed: {
    ...mapState('snackbar', ['snackbar', 'snackbarText']),

    layout () {
      return this.$route.meta.layout || 'default-layout'
    }
  }
}
</script>


<style lang="scss">
  @import '@/styles/main.scss';
</style>
