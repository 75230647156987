<template>
  <v-dialog
    :value="dialog"
    :max-width="width"
    @keydown.esc="close"
    @keydown.enter="close"
    @click:outside="close"
  >
    <v-card
      :class="{ 'pt-2': !title }"
      outlined
    >
      <template v-if="title">
        <v-card-title
          v-html="title"
          class="py-2"
        ></v-card-title>
        <v-divider></v-divider>
      </template>
      <v-card-text
        v-html="message"
        class="py-4 body-1 text--primary"
      ></v-card-text>
      <v-card-actions class="justify-center pb-4">
        <v-btn
          :color="btnColor"
          depressed
          @click.stop.prevent="close"
        >
          {{ btnText || $t('components.alert.btnText') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { VDialog, VBtn, VCard, VCardTitle, VCardText, VCardActions } from 'vuetify/lib'


export default {
  components: {
    VDialog,
    VBtn,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions
  },


  props: {
    btnText: {
      type: String
    },
    btnColor: {
      type: String
    },
    title: {
      type: String
    },
    message: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 400
    }
  },


  data () {
    return {
      dialog: true
    }
  },


  methods: {
    close () {
      this.dialog = false
      this.$destroy()
    }
  }
}
</script>
