import i18nConfig from '@/i18n.config'


const mixin = {
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },

    locales () {
      return i18nConfig.locales
    }
  },


  methods: {
    setLocale (locale) {
      // this.$i18n.locale = locale
      // this.$vuetify.lang.current = locale
      i18nConfig.setLocale(locale)
      window.location.reload()
    }
  }
}


export default mixin
