import apiClient from './apiClient'


const getCurrentUser = () => {
  return apiClient.get('/User/Current')
}

const setDisclaimerRead = () => {
  return apiClient.put('/User/DisclaimerRead')
}

const getUser = (dealerId, id) => {
  return apiClient.get(`/User/${dealerId}/${id}`)
}

const getUsers = (dealerId, chainId, skip, take, search) => {
  const params = { skip, take, search }

  if (dealerId) {
    return apiClient.get(`/User/dealer/${dealerId}`, { params })
  } else {
    return apiClient.get(`/User/${chainId}`, { params })
  }
}

const getUsersByChainId = (chainId) => {
  return apiClient.get(`/User/${chainId}`)
}

const inviteUser = (dealerId, email) => {
  return apiClient.post(`/User/${dealerId}/Invite/${email}`)
}

const reInviteUser = (dealerId, userId) => {
  return apiClient.post(`/User/${dealerId}/ReInvite/${userId}`)
}

const createUser = (dealerId, user) => {
  return apiClient.post(`/User/${dealerId}`, user)
}

const updateUser = (dealerId, id, user) => {
  return apiClient.put(`/User/${dealerId}/${id}`, user)
}

const deleteUser = (dealerId, id) => {
  return apiClient.delete(`/User/${dealerId}/${id}`)
}


export default {
  getCurrentUser,
  setDisclaimerRead,
  getUser,
  getUsers,
  getUsersByChainId,
  inviteUser,
  reInviteUser,
  createUser,
  updateUser,
  deleteUser
}
