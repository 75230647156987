import apiClient from './apiClient'


const getTemplates = (dealerId) => {
  return apiClient.get(`/Template/${dealerId}`)
}

const updateTemplates = (dealerId, templates) => {
  return apiClient.put(`/Template/${dealerId}`, templates)
}

const getSMSByLicenseNumber = (dealerId, licenseNumber) => {
  return apiClient.get(`/Template/${dealerId}/${licenseNumber}`)
}

const sendSMS = (dealerId, licenseNumber, sms) => {
  const data = {
    dealerId,
    sms
  }
  return apiClient.put(`/Template/${dealerId}/${licenseNumber}/SendSMS`, data)
}


export default {
  getTemplates,
  updateTemplates,
  getSMSByLicenseNumber,
  sendSMS
}
