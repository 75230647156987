import Vue from 'vue'
import chainApi from '@/api/chain.api'
import objectUtil from '@/utils/object.util'


const state = {
  currentChainId: null,
  previousChainId: null,
  chains: {},
  initialTableItems: {},
  tableItems: {},
  totalCount: 0
}


const getters = {
  currentChain: (state) => {
    return state.chains[state.currentChainId]
  },

  currentChainId: (state) => {
    return state.currentChainId
  },

  previousChainId: (state) => {
    return state.previousChainId
  },

  chains: (state) => {
    return Object.values(state.chains || {})
  },

  tableItems: (state) => {
    return Object.values(state.tableItems || {})
  },

  totalCount: (state) => {
    return state.totalCount || 0
  },

  chainById: (state) => (id) => {
    return state.chains[id]
  }
}


const actions = {
  async loadChains ({ commit }) {
    const response = await chainApi.getChains()
    commit('SET_CHAINS', response.data)
    return response.data
  },

  async loadChainsTableItems ({ commit }, { search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await chainApi.getChains(skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', items)
    commit('SET_TOTAL_COUNT', { totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', items)
    }

    return items
  },

  restoreInitialChainsTableItems ({ commit, state }) {
    if (state.initialTableItems) {
      commit('SET_TABLE_ITEMS', state.initialTableItems)
    }
  },

  async getChainById ({ commit }, id) {
    const response = await chainApi.getChainById(id)
    return response.data
  },

  async createChain ({ commit }, chain) {
    const response = await chainApi.createChain(chain)
    commit('CREATE_CHAIN', response.data)
    commit('SET_TOTAL_COUNT', { shift: 1 })
    return response.data
  },

  async updateChain ({ commit }, chain) {
    const response = await chainApi.updateChain(chain.id, chain)
    commit('UPDATE_CHAIN', response.data)
  },

  async deleteChain ({ commit }, id) {
    await chainApi.deleteChain(id)
    commit('DELETE_CHAIN', id)
    commit('SET_TOTAL_COUNT', { shift: -1 })
  },

  setCurrentChainId ({ commit }, id) {
    commit('SET_CURRENT_CHAIN_ID', id)
    localStorage.setItem('currentChainId', id)
  },

  restoreCurrentChainId ({ commit }) {
    const currentChainId = localStorage.getItem('currentChainId')
    commit('SET_CURRENT_CHAIN_ID', currentChainId)
    return currentChainId
  }
}


const mutations = {
  SET_CHAINS (state, items) {
    state.chains = objectUtil.arrayToObject(items)
  },

  SET_TABLE_ITEMS (state, items) {
    const itemsObject = Array.isArray(items) ? objectUtil.arrayToObject(items) : items
    Vue.set(state, 'tableItems', itemsObject)
  },

  SET_INITIAL_TABLE_ITEMS (state, items) {
    Vue.set(state, 'initialTableItems', objectUtil.arrayToObject(items))
  },

  SET_TOTAL_COUNT (state, { totalCount, shift }) {
    if (totalCount !== undefined) {
      Vue.set(state, 'totalCount', Math.max(0, totalCount))
    }
    if (shift) {
      const shifted = (state.totalCount || 0) + shift
      Vue.set(state, 'totalCount', Math.max(0, shifted))
    }
  },

  SET_CURRENT_CHAIN_ID (state, id) {
    state.previousChainId = state.currentChainId
    state.currentChainId = id
  },

  CREATE_CHAIN (state, chain) {
    if (!state.tableItems) {
      Vue.set(state, 'tableItems', {})
    }
    Vue.set(state.tableItems, chain.id, chain)
  },

  UPDATE_CHAIN (state, chain) {
    if (state.tableItems && state.tableItems[chain.id]) {
      Vue.set(state.tableItems, chain.id, chain)
    }
  },

  DELETE_CHAIN (state, id) {
    if (state.tableItems) {
      Vue.delete(state.tableItems, id)
    }
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
