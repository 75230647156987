<template>
  <v-main v-if="contentVisible" class="d-default-layout">
    <header class="d-page-header">
      <div class="d-page-wrapper d-flex justify-center align-start">
        <router-link class="d-logo" :to="{ name: 'home' }"></router-link>
        <div v-if="pageTitle" class="d-page-title">
          {{ $t(pageTitle) }}
        </div>
      </div>
    </header>

    <slot/>

    <v-footer app class="d-page-footer">
      <div class="d-page-wrapper d-flex align-center">
        <div>
          {{ $t('copyright', { year: currentYear }) }}
        </div>

        <div class="ml-auto mr-4">
          <span
            v-for="(item, index) of locales"
            :key="item.locale"
          >
            <span v-if="index !== 0" class="px-1">|</span>
            <v-btn
              v-text="item.locale"
              :class="{ 'white--text': item.locale === currentLocale }"
              icon
              small
              @click="setLocale(item.locale)"
            ></v-btn>
          </span>
        </div>
        <v-btn
          v-if="facebookLink"
          :href="facebookLink"
          target="_blank"
          icon
        >
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
      </div>
    </v-footer>
  </v-main>
</template>


<script>
import { mapState } from 'vuex'

import localeMixin from '@/mixins/locale.mixin'


export default {
  mixins: [
    localeMixin
  ],


  computed: {
    ...mapState('pageSettings', ['pageTitle']),

    facebookLink () {
      return process.env.VUE_APP_FACEBOOK_LINK
    },

    currentYear () {
      return (new Date()).getFullYear()
    },

    contentVisible () {
      const isRedirectingAfterPageReload = (this.$route.path === '/' && !this.$route.name && !this.$route.matched.length)
      const isAuthenticating = this.$auth.loading || this.$redirectingFromAuth

      return !isAuthenticating && !isRedirectingAfterPageReload
    }
  }
}
</script>
