import apiClient from './apiClient'


const getNotifications = (skip, take, search, chainId) => {
  const params = { skip, take, search }
  return apiClient.get(`/Notification/${chainId}`, { params })
}

const getNotificationsByDealer = (dealerId, skip, take, search) => {
  const params = { skip, take, search }
  return apiClient.get(`/Notification/dealer/${dealerId}`, { params })
}

const getNotificationsByVehicle = (vehicleId) => {
  return apiClient.get(`/Notification/Vehicle/${vehicleId}`)
}



export default {
  getNotifications,
  getNotificationsByDealer,
  getNotificationsByVehicle
}
