import Vue from 'vue'
import templateApi from '@/api/template.api'
import templateTypeApi from '@/api/templateType.api'


const state = {
  templateTypes: {},
  templates: {}
}


const getters = {
  templateTypeById: (state) => (id) => {
    return state.templateTypes[id]
  },

  templates: (state) => (dealerId) => {
    return state.templates[dealerId] || []
  }
}


const actions = {
  async loadTemplateTypes ({ commit }) {
    const response = await templateTypeApi.getTemplateTypes()
    commit('SET_TEMPLATE_TYPES', response.data)
  },

  async loadTemplates ({ commit }, dealerId) {
    const response = await templateApi.getTemplates(dealerId)
    const items = response.data
    commit('SET_TEMPLATES', { dealerId, items })
    return items
  },

  async updateTemplates ({ commit }, { dealerId, templates }) {
    const response = await templateApi.updateTemplates(dealerId, templates)
    commit('UPDATE_TEMPLATES', { dealerId, items: response.data })
  },

  async loadSMSByLicenseNumber ({ commit }, { dealerId, licenseNumber }) {
    const response = await templateApi.getSMSByLicenseNumber(dealerId, licenseNumber)
    const sms = response.data
    // commit('SET_SMS', { licenseNumber, sms })
    return sms
  },

  async sendSMS ({ commit }, { dealerId, licenseNumber, sms }) {
    const response = await templateApi.sendSMS(dealerId, licenseNumber, sms)
    // commit('UPDATE_TEMPLATES', { dealerId, items: response.data })
    return response
  }
}


const mutations = {
  SET_TEMPLATE_TYPES (state, items) {
    for (const templateType of items) {
      Vue.set(state.templateTypes, templateType.id, templateType)
    }
  },

  SET_TEMPLATES (state, { dealerId, items }) {
    Vue.set(state.templates, dealerId, items)
  },

  UPDATE_TEMPLATES (state, { dealerId, items }) {
    Vue.set(state.templates, dealerId, items)
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
