import Vue from 'vue'
import userApi from '@/api/user.api'
import objectUtil from '@/utils/object.util'
import { userRole } from '@/constants'


const state = {
  currentUser: null,
  initialTableItems: {},
  tableItems: {},
  totalCount: {},
  usersByChainId: []
}


const getters = {
  currentUser: (state) => {
    return state.currentUser || {}
  },

  currentUserIsChainAdmin: (state) => {
    return state.currentUser?.roleId === userRole.chainAdmin
  },

  usersTableItems: (state) => (dealerId) => {
    return Object.values(state.tableItems[dealerId] || {})
  },

  usersTotalCount: (state) => (dealerId) => {
    return state.totalCount[dealerId] || 0
  },

  usersByChainId: (state) => {
    return Object.values(state.usersByChainId) || []
  },

  userById: (state) => (id) => {
    return state.usersByChainId[id]
  }
}


const actions = {
  async loadUsersByChainId ({ commit }) {
    try {
      const response = await userApi.getUsersByChainId(state.currentUser.chainId)
      commit('SET_USERS_BY_CHAIN_ID', response.data)
    } catch (error) {
      commit('SET_USERS_BY_CHAIN_ID', [])
      throw error
    }
  },

  async loadCurrentUser ({ commit }) {
    try {
      const response = await userApi.getCurrentUser()
      const user = response.data
      commit('SET_CURRENT_USER', user)
      localStorage.setItem('currentUser', JSON.stringify(user))
      return user
    } catch (error) {
      commit('SET_CURRENT_USER', null)
      localStorage.removeItem('currentUser')
      throw error
    }
  },

  restoreCurrentUser ({ commit }) {
    const currentUserJson = localStorage.getItem('currentUser')

    if (currentUserJson) {
      const currentUser = JSON.parse(currentUserJson)
      commit('SET_CURRENT_USER', currentUser)
      return currentUser
    }

    return null
  },

  async setDisclaimerRead ({ commit }, date) {
    await userApi.setDisclaimerRead()
    commit('SET_CURRENT_USER_DISCLAIMER_READ', date)
  },

  async loadUsersTableItems ({ commit }, { dealerId, chainId, search, pageNumber, pageSize, isInitial }) {
    const skip = (pageNumber - 1) * pageSize
    const response = await userApi.getUsers(dealerId, chainId, skip, pageSize, search)

    const items = response.data
    const totalCount = parseInt(response.headers['x-total-count'])

    commit('SET_TABLE_ITEMS', { dealerId, items })
    commit('SET_TOTAL_COUNT', { dealerId, totalCount })

    if (isInitial) {
      commit('SET_INITIAL_TABLE_ITEMS', { dealerId, items })
    }

    return items
  },

  restoreInitialUsersTableItems ({ commit }, dealerId) {
    if (dealerId && state.initialTableItems[dealerId]) {
      commit('SET_TABLE_ITEMS', { dealerId, items: state.initialTableItems[dealerId] })
    }
  },

  async inviteUser ({ commit }, { dealerId, email }) {
    const response = await userApi.inviteUser(dealerId, email)
    commit('CREATE_USER', response.data)
    commit('SET_TOTAL_COUNT', { dealerId, shift: 1 })
  },

  async reInviteUser ({ commit }, { dealerId, id }) {
    const response = await userApi.reInviteUser(dealerId, id)
    commit('UPDATE_USER', response.data)
  },

  async updateUser ({ commit }, user) {
    const response = await userApi.updateUser(user.dealerId, user.id, user)
    commit('UPDATE_USER', response.data)
  },

  async deleteUser ({ commit }, { dealerId, id }) {
    await userApi.deleteUser(dealerId, id)
    commit('DELETE_USER', { dealerId, id })
    commit('SET_TOTAL_COUNT', { dealerId, shift: -1 })
  }
}


const mutations = {
  SET_USERS_BY_CHAIN_ID (state, items) {
    state.usersByChainId = objectUtil.arrayToObject(items)
  },

  SET_CURRENT_USER (state, user) {
    state.currentUser = user
  },

  SET_CURRENT_USER_DISCLAIMER_READ (state, date) {
    state.currentUser.disclaimerReadDateUtc = date
  },

  SET_TABLE_ITEMS (state, { dealerId, items }) {
    const itemsObject = Array.isArray(items) ? objectUtil.arrayToObject(items) : items
    Vue.set(state.tableItems, dealerId, itemsObject)
  },

  SET_INITIAL_TABLE_ITEMS (state, { dealerId, items }) {
    Vue.set(state.initialTableItems, dealerId, objectUtil.arrayToObject(items))
  },

  SET_TOTAL_COUNT (state, { dealerId, totalCount, shift }) {
    if (totalCount !== undefined) {
      Vue.set(state.totalCount, dealerId, Math.max(0, totalCount))
    }
    if (shift) {
      const shifted = (state.totalCount[dealerId] || 0) + shift
      Vue.set(state.totalCount, dealerId, Math.max(0, shifted))
    }
  },

  CREATE_USER (state, user) {
    const dealerId = user.dealerId

    if (!state.tableItems[dealerId]) {
      Vue.set(state.tableItems, dealerId, {})
    }
    Vue.set(state.tableItems[dealerId], user.id, user)
  },

  UPDATE_USER (state, user) {
    const dealerId = user.dealerId

    if (state.tableItems[dealerId] && state.tableItems[dealerId][user.id]) {
      Vue.set(state.tableItems[dealerId], user.id, user)
    }
  },

  DELETE_USER (state, { dealerId, id }) {
    if (state.tableItems[dealerId]) {
      Vue.delete(state.tableItems[dealerId], id)
    }
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
